import React from 'react';
import utils from '../../utils';
import { items } from '../../site/_cmsItems/items-generated';
import { BannerPanel } from './bannerPanel/view';
import { BannerItem } from './bannerItem/view';
import { Panel } from './panel/view';
import { Html } from './html/view';
import { ImagePanel } from './imagePanel/view';
import { PageTilesSpecial } from '../../components/pageTileSpecial/view';
import { RotatingPageTiles } from '../../components/rotatingPageTiles/view';
// import { PageTiles } from './pageTiles/view';
import { SingleBanner } from './singleBanner/view';
import { ContactForm } from './contactForm/view';
import { VideoPanel } from './VideoPanel/view';
import { Code } from './code/view';
import { BlogPosts } from './blogPosts/view';
import { BlogPostTitle } from './blogPostTitle/view';
import { VideoHolder } from './videoHolder/view';
import { VideoItem } from './videoItem/view';
import { MultiColumnPanel } from './multiColumnPanel/view';
import { ExpandableHtml } from './expandableHtml/view';
import { PhotoGallery } from '../../components/photoGallery/view';
import { TestimonialPanel } from '../../components/testimonial/index';
import { ExpandablePanel } from '../../components/expandablePanel/view';
import { SliderAwardPanel } from '../../components/SliderAward/index';
import { PhotoSlide } from '../../components/PhotoSlide/index';
import { RotatingReviewPanel } from '../../components/RotatingReview/index';
import cmsUtils from '../utils/cmsUtils';

export function Item(props) {
  const item = props.item;
  // console.log(item);
  const cmsOption = props.cmsOption || {};
  let itemElt = null;
  const found = utils.array.find(items, (x) => x.match(item, cmsOption));
  if (found) {
    return found.itemElt(item, cmsOption);
  }
  if (item.itemTypeCode === 'Panel') {
    itemElt = <Panel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'Html') {
    itemElt = <Html item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'BannerPanel') {
    itemElt = <BannerPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'BannerItem') {
    itemElt = <BannerItem item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'TestimonialPanel') {
    const setting = {
      startRatingColor: '#2b707d',
    };
    itemElt = (
      <TestimonialPanel item={item} cmsOption={cmsOption} {...setting} />
    );
  } else if (item.itemTypeCode === 'ImagePanel') {
    itemElt = <ImagePanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'PageTiles') {
    const type = cmsUtils.payload(item, 'TileType') || 'flip_effect';
    if (type === 'flip_effect') {
      itemElt = <PageTilesSpecial item={item} cmsOption={cmsOption} />;
    } else {
      itemElt = <RotatingPageTiles item={item} cmsOption={cmsOption} />;
    }
  } else if (item.itemTypeCode === 'SingleBanner') {
    itemElt = <SingleBanner item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'ContactForm') {
    itemElt = <ContactForm item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'VideoPanel') {
    itemElt = <VideoPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'Code') {
    itemElt = <Code item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'Blog-Posts') {
    itemElt = <BlogPosts item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'Blog-PostTitle') {
    itemElt = <BlogPostTitle item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'MultiColumnPanel') {
    itemElt = <MultiColumnPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'ExpandableHtml') {
    itemElt = <ExpandableHtml item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'VideoHolder') {
    itemElt = <VideoHolder item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'VideoItem') {
    itemElt = <VideoItem item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'PhotoGallery') {
    itemElt = <PhotoGallery item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'ExpandablePanel') {
    itemElt = <ExpandablePanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'SliderAwardPanel') {
    itemElt = <SliderAwardPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'PhotoSlide') {
    itemElt = <PhotoSlide item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === 'RotatingReviewPanel') {
    itemElt = <RotatingReviewPanel item={item} cmsOption={cmsOption} />;
  } else {
    itemElt = <div>{item.itemTypeCode}</div>;
  }

  return itemElt;
}
