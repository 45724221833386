/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
import { SiteLink } from '../../components';
import Slider from "react-slick";

export function RotatingPageTiles(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
    
    const pageTileElts = pages.map((page, index) =>
      <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
    );

    const cssClass = utils.classNames('cms_item', 'pageTiles__rotating', item.cssClass || item.anchorName || '');

    var settings = {
        dots: false,
        infinite: true,
        speed: 1700,
        autoplaySpeed: 3000,
        ////fade: true,
        autoplay: false,
        slidesToShow: (pages.length >= 4 ? 4 : pages.length),
        slidesToScroll: 1,
        responsive : [
            {breakpoint : 1024, settings : { slidesToShow: (pages.length >= 3 ? 3 : pages.length) }},
            {breakpoint : 800, settings : { slidesToShow: (pages.length >= 2 ? 2 : pages.length) }}
        ]
    };

    return (
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css = {style.pageTiles}>
        <div css={style.slider__warpper}>
            <Slider {...settings}>
                {pageTileElts} 
            </Slider>
        </div>
      </div>
    );

}
  
  function getPages(pagesSerialized) {
    if (pagesSerialized) {
      return JSON.parse(pagesSerialized);
    }
    return [];
  }
  
  function Tile(props) {
    const page = props.page;
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    const disableLink = props.disableLink;
    return (
      <div css = {style.pageTile__tile} className="pageTile__rotating">
        <SiteLink className="pageTile__rotating__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}>
            <div className="pageTile__rotating__bg" css = {style.tile__bg} style={{ backgroundImage:utils.css.bgUrlStyle(imageUrl)}} />
            <div css = {style.tile__details} className="pageTile__rotating__content__details">
                <div css={style.tile__details__title}> {page.pageTitle} </div>
                <div css={style.tile__details__desc}> {page.tileDesc || page.description} </div>
                <div css={style.tile__btn_warpper}>
                    <span css={style.tile__redMore__btn}>READ MORE</span>
                </div>
            </div>
        </SiteLink>
      </div>
  
    )
  }