import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { YoutubeEmbedPopup } from '../../../components';

export function VideoItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  // console.log('item', item);

  const youtubeCode = cmsUtils.payload(item, 'YoutubeCode');
  const posterFrameUrl = cmsUtils.payload(item, 'PosterFrameUrl');
  const captionTitleYoutube = cmsUtils.payload(item, 'CaptionTitleYoutube');
  const shortDesc = cmsUtils.payload(item, 'ShortDesc');

  const cssClass = utils.classNames(
    'cms_item',
    'videoItem',
    item.cssClass || item.anchorName || ''
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className="row video">
        <div className="col-md-5">
          <YoutubeEmbedPopup
            url={youtubeCode}
            title={captionTitleYoutube}
            posterUrl={posterFrameUrl}
          />
        </div>
        <div className="col-md-7">
          <h4>{captionTitleYoutube}</h4>
          <div dangerouslySetInnerHTML={{ __html: shortDesc }}></div>
        </div>
      </div>
    </div>
  );
}
