import { css } from '@emotion/core';
import { mq } from '../../cssInJs';

export default {
  top: css(
    mq({
      height: [44, null, 81],
      background: 'transparent',
      position: 'fixed',
      width: '100%',
      zIndex: '999',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.3s',
    })
  ),
  topScrolled: css(
    mq({
      background: 'black',
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: 'block',
      height: [70, null, 150],
      transition: 'all .2s',
      marginTop: ['3rem', null, '5.5rem'],
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [40, null, 67],
          marginTop: '0',
        })
      ),
    })
  ),
};
