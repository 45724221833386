/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './hamburgerStyle';

export function Hamburger({ opened, toggleMenu, scrolled }) {
  return (
    <button
      css={[style.button, scrolled && style.buttonScrolled]}
      type="button"
      aria-label="Menu"
      onClick={toggleMenu}
    >
      <span css={style.hamburger} className={scrolled && `hamburgerScrolled`}>
        <span
          css={[style.line1, opened && style.lineActive1]}
          className="hamburger_line1"
        ></span>
        <span
          css={[style.line2, opened && style.lineActive2]}
          className="hamburger_line2"
        ></span>
        <span
          css={[style.line3, opened && style.lineActive3]}
          className="hamburger_line3"
        ></span>
      </span>
    </button>
  );
}
