import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from './components';
import { StoreProvider } from './store';
import { ViewPage } from './cms/pages/viewPage/viewPage';
import { EditPage } from './cms/pages/editPage/editPage';
import { TopHeader } from './layout/topHeader/topHeader';
// import { Footer } from './layout/footer/footer';
import { BottomDock } from './layout/bottomDock/bottomDock';
// import { BlogView } from './site/blog/blogView';
import { AppVersionPage } from './components';
import { MediaQueryStatus } from './components';
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from 'react-helmet-async';
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AppRouteChanged } from './AppRouteChanged';
import AOS from 'aos';

function AppLayout(props) {
  const { pathname } = useRouter();
  let specialClassName = '';

useEffect(()=>{
  AOS.init({
    duration : 1200
    //delay:400,
    //once:true
  });

}, []);

  return (
    <div className={`body-content ${pathname === '/' ? 'homepage' : 'subpage'} ${specialClassName}`}>
      <AppRouteChanged />
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}
            
            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>

            {/* For ECM <Route path="/order-thankyou" children = {<OrderDetails />}/>
            <Route path="/product/:slug" children = {<ProductView />}/> */}

            <Route path="/">
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
      <Router>
        <AppLayout />
      </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;