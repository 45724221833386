/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
import { SiteLink } from '..';

export function PageTilesSpecial(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
  );
  const cssClass = utils.classNames('cms_item', 'pageTiles__special', item.cssClass || item.anchorName || '');
  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      css = {style.pageTiles}>
      {pageTileElts} 
    </div>
  );
}
function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const idx = props.idx;

  return (
    <div css = {style.pageTile__tile} className="pageTile__special">
      <div css={style.pageTile__wrapper} className="pageTile__special__wrapper">
        <SiteLink className="pageTile__special__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}>
          <div css={style.tile__flip__wrapper} className={`${idx === 0 ? 'first__item' : 'second__item'}`}>
            <div className="flip__bg__c aos-init" data-aos="flip-left" css={style.tile__bg__c}>
              <div className="pageTile__special__bg" css = {style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl)}}></div>
            </div>
            <div css = {style.tile__details} className="pageTile__special__content__details aos-init" data-aos="flip-right">  
              <div className="pageTile__special__container">
                <h4 className="pageTile__special__title">
                  {page.pageTitle}
                </h4>
                <div className="pageTile__special__desc">
                  {page.tileDesc || page.description}
                </div>
              </div>
            </div>
          </div>
        </SiteLink>
      </div>
    </div>
  )
}