import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";

export function BottomDock(props) {
  const links = [
    {
      url: "/facilities",
      title: "FACILITIES",
      icon: "facilities-orange.png",
      iconHover: "facilities.png",
    },
    {
      url: "/accommodation",
      title: "ACCOMMODATION",
      icon: "accommodation-orange.png",
      iconHover: "accommodation.png",
    },
    {
      url: "/attractions",
      title: "ATTRACTIONS",
      icon: "attractions-orange.png",
      iconHover: "attractions.png",
    },
    {
      url: "/experiences",
      title: "EXPERIENCES",
      icon: "rentals-orange.png",
      iconHover: "rentals.png",
    },
    {
      url: "/#contact",
      title: "BOOK NOW",
      icon: "online-bookings-orange.png",
      iconHover: "online-bookings.png",
    },
  ];

  const { pathname, location } = useRouter();
  const isCurrent = (link) => {
    if (pathname && pathname.toLowerCase() === link.toLowerCase()) {
      return true;
    } else if (
      location.hash &&
      location.hash.toLowerCase() === link.toLowerCase().substring(1)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className='bottomMenu'>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
    </>
  );
}
